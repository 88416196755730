@import '../stylesheets/styles';

.exclamation-wrapper {
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 40px;
    background-color: $white;
    border-radius: 6px;
    padding: 10px 15px;
    z-index: 1;

    .tooltip-text {
      @extend %caption;
      color: $active-default;
      white-space: nowrap;
    }
  }

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
      transition: all 0.2s ease-in;
    }
  }
}

.exclamation-point-icon {
  transition: all 0.2s ease-in;
  color: $active-default;
  .background {
    color: #FCF8FF;
    transition: all 0.2s ease-in;
  }

  &:hover {
    color: $white;
    .background {
      color: $active-hover;
    }
  }

  &:active {
    color: $white;
    .background {
      color: $active-default;
    }
  }
}

.card-icon {
  width: 40px;
  height: 40px;
}