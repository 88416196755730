@import './src/styles';

.password-recovery-form {
  @extend %auth-container;

  .password-recovery-title {
    @extend %auth-title;
    margin-bottom: 10px;

    &.user {
      margin-top: 22px;
      margin-bottom: 40px;
    }
  }

  .form-data {
    width: 100%;
    max-width: 350px;

    >* {
      margin-bottom: 25px;
    }

    .form {
      margin-bottom: 0;

      >* {
        margin-bottom: 20px;
        &:nth-child(3), 
        &:nth-child(4) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .password-recovery-description {
    text-align: center;
    width: 305px;
    color: $neutral;
    font-size: $font-size-smaller;
    margin-bottom: 30px;

    &.dark  {
      color: $dark;
    }

    strong {
      color: $dark;
    }
  }

  .form-description {
    padding: 0 16px;
    color: $bg-dark;
    text-align: center;
    letter-spacing: 0;
    line-height: 17px;
  }

  .security-wrapper {
    margin-bottom: 30px;
  }

  .alert-message {
    margin-bottom: 25px;
    animation: 0.3s ease-in-out marginBottom25;
  }

  @keyframes marginBottom25 {
    from {   margin-bottom: 0 }
    to {   margin-bottom: 25px }
  }

  .button {
    margin-bottom: 16px;
  }
  .password-recovery-account-text {
    margin-bottom: 0;
    text-align: center;
    color: $black;
    font-size: 14px;
  }

  .form-data{
    .text-center {
      margin-bottom: 16px;
    }
    hr {
      margin-bottom: 10px;
    }
  }
}

.expired-link-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 392px;
  padding: 40px 45px;
  margin-bottom: 100px;
  border-radius: 6px;
  background: $white;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);

  .expired-link-header {
    margin-top: 30px;
    @extend %heading-1;
    color: $header;
  }

  .expired-link-message {
    margin-top: 20px;
    @extend %caption;
    text-align: center;
    color: $neutral;
  }
}

@include mobile {
  .password-recovery-form {
    .password-recovery-name-wrapper {
      flex-direction: column;
      gap: 28px;
      height: fit-content;
      .input-wrapper {
        width: 100%;
      }
    }

    .react-tel-input {
      .country-list {
        width: 280px;
      }
    }
  }
}