@import './src/styles';


.clipboard-wrapper {
  position: relative;
}

.clipboard-icon {
    cursor: pointer;
    min-width: 10px;
    color: $neutral;

    &:hover {
      color: $active-hover;
    }

    &:active {
      color: $active-default;
    }
}

.clipboard-notification {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -50px;
  left: -50px;
  width: 112px;
  height: 39px;
  background: $white;
  border-radius: 6px;
  box-shadow: 0px 4px 38px rgba(143, 39, 199, 0.05);
  z-index: 1;

  p {
    @extend %caption;
    color: $active-default;
  }
}