@import './src/styles';

.captcha-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .captcha-error {
        margin: 5px 5px 0 -80px;
        text-align: left;
        font-size: $font-size-small;
        animation: 0.2s anim-lineUp ease-out;
        color: $error;
    }
}