@import './src/styles';

.latest-authorizations {
  width: 100%;
}

.authorizations-container {
  position: relative;

  .authorizations-header-wrapper {
    width: 100%;
    margin: 50px 0 38px 0;
    display: flex;
    border-bottom: transparent;
  }
}

.loader-placeholder {
  border-radius: 6px;
  height: 11px;
  width: 85px;
  background: linear-gradient(-90deg, $light-neutral 0%, lighten($light-neutral, 9%) 50%, $light-neutral 100%);
  background-size: 400% 400%;
  animation: stemPulse 1.2s ease-in-out infinite;
}

.authorization-link {
  position: absolute;
  top: 28px;
  right: 42px;
  @extend %heading-2;
  font-weight: 600;
  color: $active-default;
}

.authorization-table-wrapper {
  position: relative;
  width: 100%;
}

.authorizations-wrapper {
  min-width: 600px;
  width: 100%;

  .arrow-icon {
    cursor: pointer;
    margin-left: 10px;

    .up, .down {
      color: $black;
    }

    &.ASC {
      .up {
        color: $black;
      }
      .down {
        color: $light-neutral;
      }
    }

    &.DESC {
      .up {
        color: $light-neutral;
      }
      .down {
        color: $black;
      }
    }

    &:hover {
      .up, .down {
        color: $active-hover;
      }
    }
  }

  thead {
    border-bottom: 10px solid transparent;
  }

  tr {
    th {
      @extend %body;
      color: $dark-neutral;
      font-weight: normal;
      padding-right: 40px;

      &.total {
        text-align: center;
      }
    }

    td {
      @extend %caption;
      vertical-align: top;
      padding-right: 40px;
      padding-top: 10px;
    }

    td:first-child,th:first-child {
      padding-left: 31px;
    }
    td:last-child,th:last-child {
      padding-right: 31px;
    }

    .authorization-date, .authorization-purpose {
      color: $neutral;
      min-width: 130px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      .loader-placeholder {
        width: 85px;
        margin-bottom: 8px;
      }
    }

    .authorization-status {
      .loader-placeholder {
        width: 100px;
        height: 17px;
      }
    }

    .authorization-from-to {
      color: $header;

      .from-text {
        color: $neutral;
      }

      .loader-placeholder {
        width: 85px;
        margin-bottom: 8px;

        &:last-child {
          width: 179px;
        }
      }
    }

    .authorization-purpose {
      .loader-placeholder {
        width: 85px;
        margin-bottom: 8px;

        &:last-child {
          width: 179px;
        }
      }
    }

    .authorization-amount-commission {
      text-align: left;
      color: $error;
      min-width: 200px;
      &.INCOMING, &.RETURN  {
        color: $hit;
      }

      .amount {
        @extend %body;
        color: currentColor;
      }

      .commission-total {
        margin-top: 5px;
        @extend %annotation;
        color: $dark;
      }

      .loader-placeholder {
        height: 17px;
        width: 112px;
      }
    }

    .authorization-exclamation {
      padding-left: 20px;

      .loader-placeholder {
        height: 29px;
        width: 29px;
      }
    }
  }
}

.authorization-item:nth-child(even) {
  .authorization-amount-commission {
    .loader-placeholder {
      height: 17px;
      width: 176px;
    }
  }
}

.authorization-item {
  height: 80px;
  border-bottom: 1px solid $light-neutral;
}

.empty-authorizations-container {
  padding-bottom: 0;
}

.empty-authorizations {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .empty-authorizations-wrapper {
    max-width: 380px;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  .empty-authorizations-header {
    @extend %heading-1;
    margin-bottom: 20px;
  }

  .empty-authorizations-text {
    @extend %body;
    text-align: left;
  }
}

.authorization-filter-wrapper {
  width: 100%;
  padding: 0 31px;
  margin-bottom: 50px;
}
.tabs-wrapper {
  width: 100%;
  display: flex;

  .filter-tab {
    all: unset;
    @extend %body;
    color: $neutral;
    cursor: pointer;
    height: 60px;
    flex: 1;
    text-align: center;
    border-bottom: 1px solid $light-neutral;

    &:hover {
      color: $active-hover;
      border-bottom: 1px solid $active-hover;
    }

    &:active, &.active  {
      color: $active-default;
      border-bottom: 1px solid $active-default;
    }
  }
}

.filter-wrapper {

  .filter-wrapper-line {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 13px;

    .dropdown-choice-value {
      word-break: break-word;
    }

    .search {
      flex: 4;
      min-width: 190px;
    }

    .wallet {
      flex: 2;
      .input {
        width: 100%;
        min-width: 300px;
      }
    }

    .authorization-type, .currency {
      flex: 1;
      .input {
        width: 100%;
        min-width: 175px;
      }
    }

    .status {
      flex: 1;
      .input {
        width: 100%;
        min-width: 150px;
      }
    }


    .date {
      flex: 1;
      min-width: 235px;
    }
  }
}

.authorization-mobile-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
}

.compliance-requests-mobile-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  max-height: 360px;
  overflow-y: scroll;
  overflow-x: unset;
}

.authorization-item-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: 100%;
  background-color: $white;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
  border-radius: 8px;
  @extend %caption;

  .icon {
    margin-right: 10px;
    color: $light-neutral;
  }

  .item-mobile-date {
    padding-right: 5px;
  }

  .item-mobile-status, .item-mobile-from-to, .item-mobile-date {
    display: flex;
    align-items: center;
    color: $header;
  }

  .item-mobile-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .item-mobile-from-to {
    .icon {
      align-self: flex-start;
      margin-top: 3px;
      width: 20px;
      height: 15px;
    }
    .loader-placeholder {
      margin-bottom: 5px;
    }
  }

  .item-mobile-amount {
    width: 100%;
    display: flex;
    align-items: center;
    .icon {
      width: 18px;
    }
    .item-amount-text {
      margin-right: 20px;
      color: $header
    }
    .exclamation-wrapper {
      margin-left: auto;
      position: unset;
    }
    .loader-placeholder:last-child {
      margin-left: auto;
      width: 29px;
      height: 29px;
    }
  }
}

.filter-mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .filters-close-icon {
    @extend %close-icon;
    color: $white;
    .background {
      color: $neutral;
    }
  }
}

.authorization-loader-wrapper {
  width: 100%;
  padding: 8px 16px;
  .authorization-loader {
    display: flex;
    justify-content: center;
  }
}

@include mobile {
  .empty-authorizations{
    .empty-authorizations-wrapper {
      margin-left: 0;
      text-align: center;

      .empty-authorizations-text {
        text-align: center;
      }
    }

    .empty-authorizations-icon {
      width: 250px;
    }
  }

  .authorizations-container {
    .container-header {
      margin-bottom: 0;
    }

    .authorization-link {
      position: initial;
      width: 100px;
      height: 40px;
      margin-bottom: 30px;
      padding-top: 8px;
      text-align: center;
    }
  }

  .tabs-wrapper {
    overflow-x: auto;
    min-width: 320px;
    max-width: 360px;
    background-color: $white;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    .filter-tab {
      height: 37px;
      min-width: 100px;
    }
  }

  .authorization-filter-compact {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .filter-compact-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .filter-button {
        width: 60px;
      }
    }
  }

  .input-wrapper.search {
    .input-icon {
      right: 20px;
    }
  }

  .authorization-filter-wrapper {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
    padding: 0 20px;
    width: 100vw;
  }

  .filter-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .filter-button {
      max-width: 130px;
    }
  }

  .pagination-wrapper {
    margin: 30px 0 0 0;
    padding: 20px;
    width: 100%;
    align-self: center;
    justify-content: space-between;
    background-color: $white;
  }
}

@keyframes stemPulse {
  0% {
    background-position: 0 0
  }
  100% {
    background-position: -135% 0%
  }
}