@import './src/styles';

.not-found-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 90px;

  .not-found-text {
    width: 969px;
    height: 454px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .not-found-header {
      text-transform: uppercase;
      font-size: 45px;
      font-weight: 800;
      line-height: 50px;
      max-width: 376px;
      color: $neutral;
      white-space: pre-line;
      text-align: center;
    }

    .not-found-description {
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      white-space: pre-line;
      text-align: center;
      color: $neutral;
    }
  }
}

@include mobile {
  .not-found-wrapper {
    .not-found-text {
      width: 100%;
      height: 300px;
      background-size: contain;
    }
  }
}