@import './src/styles';
@import './src/components/Settings/Settings';

.profile {
  @extend %header-settings;

  .settings-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 0;

    .settings-container-right {
      @extend %settings-container;
    }
  }
}

.profile-info-wrapper {
  margin: 10px;
  min-width: 77px;
}

@include mobile {
  .profile {
    .settings-header {
      text-align: center;
      margin-bottom: 39px;
    }

    .settings-wrapper {
      box-shadow: none;
      background-color: transparent;
      gap: 10px;
      padding: 0 20px ;
      width: 100%;

      .settings-icon {
        width: 100%;
        padding: 19px 5px;
      }

      .settings-container-left {
        width: 96%;
      }

    }
  }
}