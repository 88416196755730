@import '../../uikit/stylesheets/styles.scss';

.row {
    padding: 20px 30px 20px 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 38px rgba(143, 39, 199, 0.05);
    background-color: $white;
    width: 300px;

    &.list {
        width: 100%;
        padding: 20px 0 20px 0;
        display: flex;
        align-items: center;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0;
        border-bottom: 1px solid $light-neutral;
        color: $header;
        column-gap: 15px;
        flex-wrap: nowrap;

        .row-mobile-text {
            display: none;
            text-align: left;
        }

        .date, .time {
            white-space: nowrap;
        }

        .value {
            text-align: left;
            &.tariff {
                color: $neutral;
            }
            &.type, &.date, &.time {
                color: $dark-neutral;
            }
            &.method, &.name {
                color: $dark
            }
        }

        .multiple-tariff-container {
            display: flex;
            flex-direction: column;
            .multiple-tariff-line {
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                :first-child {
                    min-width: 60px;
                }
                .multiple-tariff-value {
                    color: $dark-neutral;
                    white-space: nowrap;
                }
            }
        }
        
        .account {
            text-align: left;
        }

        .wrapper-icon {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .icon-background {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border-radius: 6px;
            background-color: $bg-2;
            .icon {
                color: $active-default;
            }
            &:hover {
                .icon {
                    color: $white;
                }
                background-color: $active-hover;
            }
            &:active {
                .icon {
                    color: $white;
                }
                background-color: $active-active;
            }
        }
    }

    &.list > :nth-child(n) {
        flex: 1;
        text-align: center;
    }

    .left-side {
        .row {
            @extend %body;
            color: $neutral;
            margin-bottom: 10px;
        }
        .wrapper-balance {
            display: flex;
            margin: 0;
            padding: 0;
        }
        .balance {
            @extend %heading-1;
            color: $header;
            margin-right: 5px;
        }
        .currency {
            @extend %heading-1;
            color: $neutral;
        }
    }

    .buttons-wrapper {
        display: flex;
        gap: 15px;
    }
}

@include mobile {
    .row {
        max-width: 100%;
    }
    .row.list {
        flex-direction: column;
        background-color: $white;
        box-shadow: 0px 4px 38px rgba(143, 39, 199, 0.05);
        border-radius: 6px;
        border-bottom: none;
        align-items: flex-start;
        padding: 0;
        gap: 0px;
        .row-mobile-text {
            display: inline;
            color: $dark-neutral;
        }
        .multiple-tariff-container {
            display: flex;
            flex-direction: column;
            .multiple-tariff-line {
                column-gap: 30px;
            }
        }
        .row-item {
            padding: 10px;
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 10px;
            border-bottom: 1px solid $light-neutral;
            &:last-child {
                border-bottom: none;
            }
        }
    }
}