@import 'src/uikit/stylesheets/variables.scss';

.pagination-wrapper {
  margin-top: 30px;
  display: flex;
  gap: 30px;
  align-items: center;
  align-self: flex-end;
  margin-right: 42px;

  .pagination-size-dropdown {
    max-width: 135px;
    @include caption();
  }

  .pagination-pages {
    display: flex;
    gap: 10px;
    button {
      all: unset;
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: $bg-2;

      svg {
        color: $active-default;
      }

      &:hover {
        background-color: $active-hover;
      }

      &:active, &.active {
        background-color: $active-default;
        color: $white;

        svg {
          color: $white;
        }
      }
    }

    .loader-placeholder {
      height: 29px;
      width: 29px;
    }
  }

  .pagination-counter {
    @extend  %body;
    color: $light-neutral;
    strong {
      color: $dark-neutral;
    }
  }
}

@include mobile {
  .pagination-wrapper {
    margin: 30px 0 0 0;
    padding: 20px;
    gap: 20px;
    width: 100%;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    background-color: $white;
  }
}