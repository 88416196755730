@import 'src/styles';

.form-stepper-wrapper {
  display: flex;
  width: 100%;
  max-width: 482px;

  .form-stepper {
    width: 100%;
    padding: 0;

    button {
      cursor: default;
    }

    button.active {
      background-color: $active-hover;
    };

    button.completed {
      background-color: $active-default;
    };

    span {
      border-top-width: 2px;
      border-radius: 20px;
    }

    span.active {
      border-color: $active-hover;
    };

    span.completed {
      border-color: $active-default;
    };
  }
}
