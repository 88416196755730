@import 'src/uikit/stylesheets/styles';

.onHold {
  color: #FFCF3C;
}

.created .pending, .processing {
  color: $neutral;
}

.completed, .ACCEPTED, .CLEARED {
  color: $hit;
}

.rejected, .failed, .DECLINED, .REVERSED {
  color: $error;
}
