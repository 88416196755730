@import './src/styles';

.sms-code-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .alert-message {
    margin-bottom: 20px;
    justify-content: center;
  }

  .resend-wrapper {
    button {
      margin-left: 10px;
    }
  }
}

.sms-code-wrapper-messenger {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  gap: 20px;

  .sms-description-messenger {
    font-size: $font-size-smaller;
    color: $neutral;
    text-align: center;
    max-width: 302px;
    white-space: pre-wrap !important;

    strong {
      color: $dark;
      font-weight: normal;
    }
  }

  .alert-message {
    justify-content: center;
  }

  .resend-wrapper {
    button {
      margin-left: 10px;
    }
  }
}

.sms-code-wrapper-card-creation-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  .alert-message {
    margin-bottom: 20px;
    justify-content: center;
  }

  .resend-wrapper {
    margin-top: 20px;
    button {
      margin-left: 10px;
    }
  }
}