@font-face {
  font-family: "Manrope-Regular";
  src:local("Manrope-Regular"),
  url("../assets/fonts/Manrope-Regular.ttf") format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: "Manrope-SemiBold";
  src:local("Manrope"),
  url("../assets/fonts/Manrope-SemiBold.ttf") format('truetype');
  font-weight: normal;
}
@import '../uikit/stylesheets/variables';
@import 'svg';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  position: fixed;
  height: 100%;
  width: 100%;
  font-family: 'Manrope-Regular', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: $bg-secondary;
  background-color: $background-color;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: $background-color;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: $light-neutral;
  }

  ::-webkit-scrollbar-thumb:hover {
    width: 15px;
  }
}

// Removing input background colour for Chrome autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

#root {
  min-height: 100%;
  overflow: auto;
}

h1 {
  color: $bg-dark;
}

p {
  margin-bottom: 0;
}

.page {
  align-self: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-height: 100vh;

  &.authorization  {
    justify-content: center;
    height: 100%;
    background-color: $white;
  }

  .footer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    margin-bottom: 20px;

    .support-link {
      text-align: center;
      font-size: $font-size-smaller;
    }
  }
}

.icon-logo-wrapper {
  margin: 20px 0;
}

.icon-logo {
  max-width: 160px;
  max-height: 60px;
}

.content {
  padding: 20px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .application & {
    padding: 50px;
    margin: 0 auto;
    width: 100%;
    max-width: $desktop-width;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
  }
}

.center-children {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.unset {
  all: unset;
  cursor: pointer;
}

%close-icon {
  cursor: pointer;
  transition: all 0.2s ease-in;
  color: $active-default;
  .background {
    color: $bg-2;
    transition: all 0.2s ease-in;
  }

  &:hover {
    color: $white;
    .background {
      color: $active-default;
    }
  }

  &:active {
    color: $active-active;
    .background {
      color: $bg-2;
    }
  }
}

%auth-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 482px;
  border-radius: 6px;
  padding: 0 21px;

  .alert-message {
    justify-content: center;
  }
}

%auth-title {
  line-height: 34px;
  font-size: 25px;
  color: $header;
  text-align: center;
}

.back-button {
  cursor: unset;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 20px;
  p {
    color: $neutral;
    font-size: 14px;
  }
}

.link-button {
  border: none;
  background: inherit;
  margin: 0;
  padding: 0;
}

.right-section {
  flex: 1 1;
  flex-wrap: wrap;
}

.datepicker {
  display: flex;
  border: 1px solid $light-neutral;
  height: 60px;
  border-radius: 6px;
  align-items: center;

  &.selected {
    border-color: $active-default;

    label {
      color: $active-default;
      border-color: $active-default;
    }
  }

  label {
    margin-left: 20px;
    padding-right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid $light-neutral;
    @extend  %caption;
    color: $light-neutral;
  }

  .react-datepicker-wrapper {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;

    .datepicker-value-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 120px;
    }
  }

  .date-input {
    all: unset;
    border: none;
    width: 100%;
    @extend %body;

    &::placeholder {
      color: $light-neutral;
    }
  }

  .date-icon {
    position: relative;
    z-index: 0;
    min-width: 20px;
    right: 10px;
    color: $dark;
  }
}

.copyright {
  position: relative;
  color: $neutral;
  text-align: center;
  font-size: $font-size-smaller;
}

%header-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

%header-list > :nth-child(n) {
  flex: 1;
  text-align: center;
}

%settings-container {
  max-width: 650px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;

  .profile-info-wrapper.left {
    width: 381px;
  }

  .profile-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .profile-header {
      @extend %body;
      color: $neutral;
      margin-bottom: 10px;
    }

    .profile-info {
      @extend %heading-2;
    }
  }
}

// BUTTON STYLES FOR LOGOUT/AUTOLOGOUT/DELETE_REPRESENTATIVE POP-UPS
.scheme-wrapper-buttons {
  display: flex;
  flex-direction: row;
  gap: 30px;

  .scheme-button {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include mobile {
  .html, body {
    top: 0;
    left: 0;
    overflow: auto;
  }

  #root {
    min-height: 100vh;
    overflow-y: auto;
  }

  .page.authorization {
    background-color: $white;
    min-height: 100vh;
    height: unset;
    overflow: hidden;

    .icon-logo {
      margin: 0;
    }
  }

  .content {
    background-color: $white;
    margin: 0;

    .application & {
      padding: 20px;
      justify-content: center;
    }
  }

  %auth-title {
    line-height: 24px;
    font-size: 20px;
    color: $header;
    text-align: center;
    font-weight: 600;
  }

  %auth-container {
    width: 100vw;
    background-color: inherit;
    box-shadow: none;
  }

  %settings-container {
    max-width: 100%;
    background-color: $white;
    flex-direction: column;
    gap: 10px;

    .profile-wrapper {
      gap: 10px;
    }

    .profile-info-wrapper.left {
      width: 100%;
    }
  }

  .page %auth-container > :last-child {
    margin-bottom: 0;
  }

  .datepicker {
    label {
      display: flex;
      width: 84px;
      justify-content: center;
    }

    .date-icon {
      right: 15px;
    }
  }

  .scheme-wrapper-buttons {
    flex-direction: column;
    gap: 20px;

    .scheme-button {
      width: 240px;
      min-width: 100px;
    }
  }

  @supports not (-webkit-touch-callout: none) {
    .footer {
      bottom: 20px;
    }
}

  .footer {
    display: flex;
    justify-content: center;
  }

  .copyright {
    padding: 0 15px;
    margin-top: 0;
    max-width: 280px;
    @supports (-webkit-touch-callout: none) {
      margin-bottom: 100px;
    }
  }

}

