@import '../../styles';

.verification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background-color: $white;
  border-radius: 6px;
  width: fit-content;

  .bank-icon {
    min-width: 40px;
    margin-bottom: 22px;
  }

  .corporate-icon {
    min-width: 40px;
    margin-bottom: 16px;
  }

  .bank-header {
    @extend  %display-h1;
    margin: 0 0 16px 0;
    &.account {
      margin-bottom: 24px;
    }
  }

  .bank-description {
    @extend %body;
    margin-bottom: 24px;
    max-width: 440px;
  }

  .button {
    width: 185.15px;
  }
}

.shufti-verification-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 100px;

  .shufti-verification-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .shufti-verification-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 350px;
      gap: 40px;
    }
  }
}

.manual-verification {
  &-container {

  }

  &-wrapper {
    width: 100%;
    padding: 0 30px;
    text-align: left;
  }

  &-text {
    max-width: 500px;
  }

  &-button-container {
    margin-top: 30px;
  }
}

.corporate-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

.sumsub-verification-container {
  width: 100%;
  min-height: 70vh;

  .sumsub {
    width: 100%;
  }
}

@include mobile {
  .verification {
    flex-direction: column;
    align-items: flex-start;

    .bank-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .bank-icon {
      margin: 0 0 20px 0;
    }

    .bank-header {
      margin-bottom: 20px;
      text-align: left;
      width: 280px;
    }

    .bank-description {
      margin: 0 0 20px 0;
      text-align: left;
      width: 280px;
    }
  }

  .shufti-verification-container {
    .shufti-verification-wrapper {
      margin-top: 20px;
    }
  }

  .sumsub-verification-container {
    justify-content: flex-start;
  }
}