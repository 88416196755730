@import '../stylesheets/styles';

@mixin btnFileColor($back, $text, $icon:null) {
  background-color: $back;
  color: $text;
  .icon {
    @if $icon != null {
      color: $icon;
    } @else {
      color: $text;
    }
  }
}

.pdf-button {
  position: relative;
  padding: 0 10px;
  display: flex;
  gap: 7px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: $bg-2;
  color: $active-default;
  border: none;
  @extend %body;
  transition: all 0.2s ease-in-out;

  &.disabled {
    cursor: auto;
    @include btnFileColor($light-neutral, $white);
  }

  &.disabled:hover {
    @include btnFileColor($light-neutral, $white);
  }

  .icon {
    color: $active-default;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    @include btnFileColor($bats-hover, $white);
  }

  &:active {
    @include btnFileColor($active-default, $white);
  }

  &.input {
    border: 1px solid $light-neutral;
    @include btnFileColor(transparent, $neutral, $dark);

    &.disabled {
      cursor: auto;
      @include btnFileColor($light-neutral, $white);
    }

    &.disabled:hover {
      @include btnFileColor($light-neutral, $white);
    }

    &:hover {
      @include btnFileColor($bg-2, $active-default);
    }
  }

  .pdf-button-tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    display: flex;
    text-align: left;
    top: 60px;
    left: -40px;
    background-color: $white;
    border-radius: 6px;
    padding: 10px 15px;
    z-index: 2;
    max-width: 250px;
    width: fit-content;

    .pdf-button-tooltip-text {
      @extend %caption;
      color: $active-default;
      white-space: pre;
    }
  }

  &:hover {
    .pdf-button-tooltip {
      visibility: visible;
      opacity: 1;
      transition: all 0.2s ease-in;
    }
  }
}

@include mobile {
  .pdf-button.input {
    width: 100%;
    padding: 0 15px 0 20px;
    justify-content: space-between;
    @include btnFileColor(transparent, $active-default);
  }

  .pdf-button {
    .pdf-button-tooltip {
      left: 0;
    }
  }
}