@import './src/styles';

.container-web {
  padding: 100px 0 100px 0;
}

.between-accounts-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 330px;

  .between-accounts-inputs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 40px;

    .between-accounts-description-input {
      textarea {
        height: fit-content;
        padding: 20px 16px;
      }
    }
  }

  .between-accounts-inputs-wrapper > :nth-child(n) {
    width: 100%;
  }

  .between-accounts-action-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 42px;
  }
}

@include mobile {
  .page-title-wrapper {
    width: 100%;
    margin: 10px 0 40px 0;
    text-align: center;
  }

  .transactions-container {
    width: 280px;
  }

  .between-accounts-wrapper {
    width: 100%;
    max-width: 330px;
    min-width: 280px;
    margin-top: 20px;
  }
}