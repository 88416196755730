@import '../stylesheets/styles';

.radio-group {
  display: flex;
  align-items: center;
  width: 210px;
  border: 1px solid $light-neutral;
  border-radius: 20px;
  height: 30px;

  &.disabled-left {
    pointer-events: none;
  }

  .radio-button {
    box-sizing: border-box;
    width: 50%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    color: $light-neutral;

    svg {
      margin-right: 4px;
      color: currentColor;
    }

    p {
      line-height: 22px;
      color: currentColor;
    }

    &.selected {
      border: 1px solid $light-neutral;
      svg {
        color: $active-default;
      }

      p {
        color: $dark;
      }
    }
  }
}
