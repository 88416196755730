@import './src/styles';

%header-settings {

  .settings-header {
    @extend %heading-1;
    margin-top: 0;
    margin-bottom: 50px;
  }

  .settings-wrapper {
    padding: 0 50px 0 50px;
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .settings-container-left {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    .settings-icon {
      padding: 20px 5px;
      border-bottom: 1px solid $light-neutral;
      width: 100%;
      max-width: 220px;
      white-space: nowrap;
      &:active, &.active {
        border-bottom: 1px solid $active-default;
      }
    }

    .settings-container-right {
      width: 100%;
      display: flex;
      justify-content: center;
      box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
      border-radius: 6px;
    }

    .settings-input-wrapper {
      margin-top: 30px;
      margin-bottom: 40px;
      max-width: 372px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .settings-input {
        max-width: 350px;
        min-width: unset;
        width: 100%;
        margin-bottom: 19px;
      }
    }
  }
}

.manage2fa-main-wrapper {
  display: flex;
  flex-direction: column;
  width: 100% ;
  padding: 24px;
  background-color: $white;

  .settings-container-manage2fa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 35px 30px;
    width: 100%;
    box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
    border-radius: 6px;

    .manage2fa-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 360px;

      .manage2fa-status {
        @extend %caption;
        color: $hit;
        margin-bottom: 20px;
      }

      .manage2fa-title {
        @extend %heading-1;
        color: $header;
        margin-bottom: 28px;
      }

      .manage2fa-description {
        @extend %body;
        color: $bg-secondary;
        margin-bottom: 36px;
      }

      .manage2fa-actions-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;

        .manage2fa-button {
          height: 60px;
          width: fit-content;
          background-color: inherit;
          border: 1px solid $bg-light;
          padding: 20px;
          @extend %caption;
          color: $dark;
        }
      }
    }

    .google2fa-icon-wrapper {
      max-width: 225px;
      max-height: 225px;

      .google2fa-icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.settings-google2fa-popup {
  padding: 40px !important;
  text-align: center;
}

.settings-container-user {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
  border-radius: 6px;
  @extend %settings-container;
}

.settings {
  @extend %header-settings;

  .settings-alert {
    margin-bottom: 34px;
  }
}

@include mobile {
  .settings {

    .settings-wrapper {
      padding: 0 20px;
      width: 100vw;
      box-shadow: none;
    }

    .settings-header {
      text-align: center;
      margin-bottom: 39px;
    }

    .settings-wrapper {
      padding: 0 20px;
      width: 100vw;
      box-shadow: none;

      .settings-input-wrapper {
        margin-bottom: 0;
      }

      .settings-container-left {
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;

        .settings-icon {
          min-width: 180px;
          width: 100%;
          padding: 20px 20px 20px 0;
          color: $light-neutral;

          &.active {
            color: $active-default;
          }
        }
      }

      .settings-container-right {
        background-color: $white;
        flex-direction: column;
        padding: 20px;
      }
    }
  }

  .manage2fa-main-wrapper {
    .settings-container-manage2fa {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding: 20px 30px;

      .manage2fa-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .manage2fa-description {
          text-align: center;
        }
      }


      .google2fa-icon-wrapper {
        max-width: 150px;
        max-height: 150px;

        .google2fa-icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
