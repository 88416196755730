@import 'src/styles';

.currency-icon-wrapper {
  display: flex;

  svg {
    width: 32px;
    height: 32px;
  }
}
