@import 'src/styles';

.dashboard {
  display: flex;
  gap: 50px;

  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1075px;
    margin-bottom: -20px;

    .dashboard-header-text {
      @extend %heading-1;
    }

    .view-all-accounts-link {
      @extend %heading-2;
      font-weight: 600;
      color: $active-default;
    }
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 25px;
    box-sizing: border-box;
  }

  .account-info {
    height: fit-content;
  }
}

@media screen and (max-width: 1300px) {
  .dashboard {
    .card-wrapper {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

@media screen and (max-width: 1050px) {
  .dashboard {
    .card-wrapper {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@include mobile {
  .dashboard {
    .card-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
}
