@import '../stylesheets/styles';

%input-shared {
  position: relative;

  label {
    position: absolute;
    top: -10px;
    left: 8px;
    padding: 0 8px 2px 8px;
    border-radius: 6px;
    line-height: 11px;
    font-size: $font-size-smaller;
    color: $dark;
    z-index: 1;
  }

  .input {
    padding: 0 16px;
    height: 60px;
    min-width: 100%;
    max-width: 100%;
    border: 1px solid $light-neutral;
    background-color: $white;
    color: $dark;
    border-radius: 6px;
    transition: color 0.2s ease-in;

    &::placeholder {
      color: $neutral;
      font-size: 14px;
    }

    &:-webkit-autofill {
      -webkit-background-clip: text;
    }

    &:hover {
      color: $dark-neutral;
      border-color: $neutral;
    }

    &:focus {
      outline: none;
      color: $dark;
      border-color: $dark;
    }
  }

  .input-icon {
    position: absolute;
    color: $neutral;
    top: 20.5px;
    right: 10px;
    transition: all 0.2s ease-in;

    &:hover {
      color: $dark;
    }

    &.eye {
      cursor: pointer;
      display: flex;
      gap: 10px;
    }
  }

  &.error {
    label {
      color: $error;
    }
    .input {
      outline: none;
      border-color:  $error;
    }
    .input-icon {
      color: $error;
    }
  }

  &.hit {
    label {
      color: $hit;
    }
    .input {
      outline: none;
      border-color: $hit;
    }
    .input-icon {
      color: $hit;
    }
  }

  .input-under-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .input-symbols-count-text {
      text-align: left;
      margin: 5px 10px 0 0;
      font-size: $font-size-small;
      color: $dark;
      animation: 0.2s anim-lineUp ease-out;
    }

    .input-sub-text {
      text-align: left;
      margin: 5px 5px 0 10px;
      font-size: $font-size-small;
      color: $dark;
      animation: 0.2s anim-lineUp ease-out;
    }

    .input-error {
      color: $error;
      text-align: left;
    }
  }


  &.disabled {
    .input {
      background-color: $light;
      border: none;
      padding-right: 10px;

      &::placeholder {
        color: $dark;
      }
    }
  }
}

.d-none {
  display: none;
  z-index: -1;
  opacity: 0;
}

.react-tel-input {
  margin-bottom: 25px;
  border: 1px solid $light-neutral;
  border-radius: 6px;

  &:hover {
    border-color: $header;
  }

  .special-label {
    display: block;
    padding: 0 8px;
    @extend %caption;
    color: $dark;
    left: 15px;
    top: -15px;
  }

  .form-control {
    width: 100%;
    height: 60px;
    color: $dark;
    @extend %body;
    padding-left: 60px;
    background-color: transparent;
    border: none;

    &:focus {
      box-shadow: none;
      border-color: transparent;
    }
  }

  .flag-dropdown, .flag-dropdown.open {
    background-color: transparent;
    border: none;
    border-radius: unset;
    padding-left: 16px;

    .selected-flag {
      background-color: transparent;
      .arrow {
        visibility: hidden;
      }
    }
  }

  .country-list {
    width: 350px;
    max-height: 180px;
    margin-left: -14px;

    .country {
      height: 60px;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
  }
}

.custom-react-tel-input {
  margin: 0 !important;
  .special-label {
    left: 11px;
    padding: 0 4px;
    @extend %caption;
    font-family:'Manrope-Regular', sans-serif;
    color: $dark !important;
  }
}

.invalid-tel-number {
  border: 1px solid $error !important;
  .special-label {
    color: $error !important;
  }
}

.hit-tel-number {
  border: 1px solid $hit !important;
  .special-label {
    color: $hit !important;
  }
}

.input-wrapper {
  @extend %input-shared;

  &.icon {
    .input {
      padding: 0 55px 0 16px;
    }
  }

  &:hover {
    .eye {
      color: $dark;
      &.error {
        color: $error;
      }
    }
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.text-editor-wrapper {
  position: relative;

  .text-editor-label {
    position: absolute;
    top: -10px;
    left: 8px;
    padding: 0 8px;
    border-radius: 6px;
    background: $white;
    line-height: 11px;
    @extend %caption;
    color: $dark;
  }

  .text-editor-under-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .text-editor-symbols-count-text {
      text-align: left;
      margin: 5px 10px 0 0;
      font-size: $font-size-small;
      color: $dark;
      animation: 0.2s anim-lineUp ease-out;
    }

    .text-editor-sub-text {
      text-align: left;
      margin: 5px 5px 0 10px;
      font-size: $font-size-small;
      color: $dark;
      animation: 0.2s anim-lineUp ease-out;
    }

    .text-editor-sub-text-error {
      color: $error;
      text-align: left;
    }
  }

  div {
    word-break: break-word;
  }
}

@include mobile {
  %input-shared {
    .input {
      background-color: inherit;
      padding-left: 20px;
    }

    .input-icon {
      right: 10px;
    }

    label {
      line-height: 12px;
    }
  }

  .text-editor-wrapper {
    position: relative;

    .RichTextEditor {
      background: inherit !important;
      border: 1px solid $bg-light !important;
      border-radius: 6px !important;
      font-family: $font-family !important;
      font-size: 14px !important;
    }

    .text-editor-label {
      position: absolute;
      top: -10px;
      left: 8px;
      padding: 0 8px;
      border-radius: 6px;
      background: $white;
      line-height: 12px;
      @extend %caption;
      color: $dark;
    }
  }
}

.RichEditor-root {
  padding: 15px;
  background: $white;
  border: 1px solid #ddd;
  border-radius: 6px !important;
  font-family: $font-family;
  font-size: 14px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  font-family: $font-family;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  font-family: $font-family;
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  font-family: $font-family;
  min-height: 140px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: $font-family;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: $font-family;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: $font-family;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.RichTextEditor {
  background: $white !important;
  border: 1px solid $bg-light !important;
  border-radius: 6px !important;
  font-family: $font-family !important;
  font-size: 14px !important;

  > div:first-child {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.RichTextEditor.disabled {
  background: $light !important;
  border: none !important;
  word-break: break-all;

  > div:first-child {
    justify-content: flex-start !important;
  }
}

.RichTextEditor,
.public-DraftEditor-content {
  font-family: $font-family;
  min-height: 140px;
}

.DraftEditor-root {
  [class*='RichTextEditor__paragraph'] {
    margin: 0 !important;
  }
}

.justify-content-flex-end {
  justify-content: flex-end!important;
}
