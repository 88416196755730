@import './src/styles';

.container-max-height {
  padding: 100px 0 0 0;
}

%header-messages {

  .messages-header {
    @extend %heading-1;
    margin-top: 0;
    margin-bottom: 50px;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 60vh;
  }

  .messages-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 60vh;
    padding: 0 50px 20px 50px;

    .messages-empty-container {
      padding: 0 50px 0 50px;
      width: 100%;
      min-height: 60vh;
      display: flex;
      flex-direction: row;

      .messages-empty-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 324px;

        .messages-empty-icon {
          margin-bottom: 26px;
        }

        .messages-empty-header {
          @extend %heading-1;
          margin-bottom: 16px;
        }

        .messages-empty-body {
          @extend %caption;
          color: $neutral;
          margin-bottom: 56px;
        }

        .messages-empty-button {
          width: fit-content;
          padding: 0 34px;
          white-space: nowrap;

          .messages-empty-button-label {
            @extend %body;

            margin-right: 16px;
          }
        }
      }
    }

    .messages-left-side {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 350px;
      padding-right: 20px;

      .write-new-message-button-container {
        height: fit-content;

        .write-new-message-button {
          width: fit-content;
          height: 60px;
          padding: 0 34px;
          margin-bottom: 30px;
          white-space: nowrap;

          .write-new-message-button-label {
            @extend %body;

            margin-right: 16px;
          }
        }
      }

      .topics-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 900px;
        padding-right: 10px;
        gap: 10px;
        overflow: auto;

        .topic-block {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 20px;
          border: 1px solid $neutral;
          border-radius: 6px;
          background-color: $white;

          .unread-topic-mark {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            color: $error;
            background-color: $error;
          }

          .topic-last-message-date-block {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            margin-bottom: 16px;

            .topic-last-message-date-just-now {
              @extend %caption;
              color: $active-default;
            }

            .topic-last-message-date-text {
              @extend %caption;
              color: $neutral;
            }

            .unread-topic-mark {
              margin-left: -6px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              color: $error;
              background-color: $error;
            }
          }

          .topic-last-message-content {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            @extend %body;
            color: $header;
            text-align: left;
          }
        }

        .topic-block:hover {
          border: 2px solid $active-hover;
        }

        .topic-block-active {
          border: 2px solid $active-default;
        }
      }
    }

    .messages-right-side {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: fit-content;
      padding: 30px;
      box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
      border-radius: 6px;

      .chat-subject-wrapper {
        width: 100%;
        margin-bottom: 52px;
        padding-right: 20px;

        .chat-subject-text {
          display: block;
          @include heading-1;
          color: $header;
          word-break: break-word;
        }
      }

      .chat-messages-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 460px;
        padding-right: 20px;
        gap: 20px;
        overflow: auto;

        .chat-message-block {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-bottom: 20px;
          border-bottom: 1px solid $neutral;

          .chat-message-head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .chat-message-date-block {
              display: flex;
              flex-direction: row;
              gap: 8px;

              .chat-message-date-just-now {
                @extend %body;
                color: $active-default;
              }

              .chat-message-date-text {
                @extend %body;
                color: $neutral;
              }
            }

            .chat-message-author {
              @extend %body;
              color: $neutral;
            }
          }

          .chat-message-content {
            word-break: break-word;
            @extend %body;
            color: $header;
          }

          .chat-message-attachments-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 20px;

            .chat-message-attachments-label {
              @extend %heading-2;
              color: $neutral;
            }

            .chat-message-attachments-preview-wrapper {
              display: flex;
              flex-direction: row;
              width: 100%;
              height: 110px;
              gap: 15px;
              padding: 10px 1px 5px 0;
              overflow-y: auto;

              .chat-message-attachment-wrapper {
                position: relative;
                width: 120px;
                height: 80px;
                border-radius: 6px;
                box-shadow: 0 0 1px 1px rgba(143, 39, 199, 0.1);

                .chat-message-attachment {
                  width: 120px;
                  height: 80px;
                  object-fit: cover;
                  border-radius: 6px;

                  .skeleton {
                    border-radius: 6px;
                    background: $neutral ;
                  }
                }

                .overlay {
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  height: 100%;
                  border-radius: 6px;
                  background: linear-gradient(0deg, rgba(127, 141, 160, 0.5), rgba(127, 141, 160, 0.5));
                  opacity: 1;
                  transition: opacity 0.3s ease;

                  .overlay-top-half {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50%;
                    width: 100%;
                    padding: 5px;
                    border-radius: 6px;
                    background-color: $neutral;
                    opacity: 0.8;

                    .chat-message-attachment-name {
                      @extend %annotation;
                      color: $white;
                    }
                  }

                  .overlay-bottom-half {
                    display: flex;
                    flex-direction: row-reverse;
                    height: 50%;
                    width: 100%;
                    padding: 5px;
                    gap: 10px;

                    .overlay-icon-wrapper {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background: $white;
                      padding: 5px;
                      border-radius: 6px;
                      box-sizing: border-box;

                      svg {
                        margin: 0;
                      }
                    }

                    .overlay-icon-wrapper:hover {
                      background: $active-default;

                      svg path:nth-of-type(2) {
                        fill: $white;
                      }

                      svg path:nth-of-type(1) {
                        stroke: $white;
                      }
                    }
                  }
                }
              }

              .chat-message-attachment-wrapper:hover .overlay {
                opacity: 1;
              }
            }
          }
        }
      }

      .chat-form.has-scroll {
        padding-right: 30px;
      }

      .chat-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;
        padding-right: 20px;

        .chat-form-text-editor-wrapper {
          position: relative;

          .chat-form-text-editor-label {
            position: absolute;
            top: -10px;
            left: 8px;
            padding: 0 8px;
            border-radius: 6px;
            background: $white;
            line-height: 11px;
            @extend %caption;
            color: $dark;
          }
        }

        .chat-form-attachments {
          margin-top: 40px;

          .input {
            padding: 15px;

            .files-list-wrapper {
              width: 100%;
            }
          }
        }

        .chat-form-button {
          margin-top: 40px;
        }
      }

      .new-message-form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .new-message-form-subject-input {
          height: fit-content;
          margin-bottom: 46px;
          @extend %body;
          color: $header;
        }

        .new-message-form-message-input {
          height: fit-content;

          textarea {
            padding: 16px;
            height: fit-content;
            resize: none;
            @extend %body;
            color: $header;
          }
        }

        .new-message-form-attachments {
          margin-top: 40px;

          .input {
            padding: 15px;

            .files-list-wrapper {
              width: 100%;
            }
          }
        }

        .new-message-form-button {
          margin-top: 40px;
        }
      }
    }
  }

  .actions-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    .edit-message-button {
      width: 100%;
      max-width: 140px;
    }

    .confirm-button {
      width: 100%;
    }
  }
}


.messages {
  @extend %header-messages;

  .messages-alert {
    margin-bottom: 34px;
  }
}

@include mobile {
  %header-messages {
    width: 100%;

    .messages-header {
      @extend %heading-1;
      margin-top: 0;
      margin-bottom: 50px;
    }

    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 60vh;
    }

    .messages-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      min-height: unset;
      padding: 0 0 20px 0;
      margin-top: 10px;

      .messages-empty-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: unset;
        padding: 20px;

        .messages-empty-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          max-width: 324px;

          .messages-empty-icon {
            margin-bottom: 26px;
          }

          .messages-empty-header {
            @extend %heading-1;
            margin-bottom: 16px;
          }

          .messages-empty-body {
            @extend %caption;
            text-align: center;
            margin-bottom: 56px;
            color: $neutral;
          }

          .messages-empty-button {
            width: fit-content;
            padding: 0 34px;
            white-space: nowrap;

            .messages-empty-button-label {
              @extend %body;

              margin-right: 16px;
            }
          }
        }
      }

      .messages-left-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: unset;
        padding: 0;
        box-shadow: unset;

        .write-new-message-button-container {
          height: fit-content;

          .write-new-message-button {
            width: fit-content;
            height: 60px;
            padding: 0 34px;
            margin-bottom: 30px;
            white-space: nowrap;

            .write-new-message-button-label {
              @extend %body;

              margin-right: 16px;
            }
          }
        }

        .topics-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-height: 624px;
          padding-right: 10px;
          gap: 10px;
          overflow: auto;

          .topic-block {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 10px;
            border: 1px solid $neutral;
            border-radius: 6px;
            background-color: transparent;

            .topic-last-message-date-block {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 16px;
              margin-bottom: 20px;

              .topic-last-message-date-just-now {
                @extend %caption;
                color: $active-default;
              }

              .topic-last-message-date-text {
                @extend %caption;
                color: $neutral;
              }
            }

            .topic-last-message-content {
              display: block;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              @extend %body;
              color: $header;
            }
          }

          .topic-block:hover {
            border: 2px solid $active-hover;
          }

          .topic-block-active {
            border: 1px solid $neutral;
          }
        }
      }

      .messages-right-side {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 560px;
        height: fit-content;
        padding: 20px 0 30px 0;
        box-shadow: unset;
        border-radius: 6px;

        .chat-subject-wrapper {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 30px;

          .chat-subject-text {
            display: block;
            @include heading-2;
            font-weight: 600;
            color: $header;
            word-break: break-word;
          }
        }

        .chat-messages-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-height: 460px;
          padding-right: 10px;
          gap: 20px;
          overflow: auto;
          border-radius: 6px;

          .chat-message-block {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid $neutral;

            .chat-message-head {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 8px;
              align-items: flex-start;

              .chat-message-date-block {
                display: flex;
                flex-direction: row;
                gap: 8px;

                .chat-message-date-just-now {
                  @extend %body;
                  color: $active-default;
                }

                .chat-message-date-text {
                  @extend %body;
                  color: $neutral;
                }
              }

              .chat-message-author {
                @extend %body;
              }
            }

            .chat-message-content {
              word-break: break-all;
              @extend %body;
              color: $header;
            }

            .chat-message-attachments-wrapper {
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-top: 16px;

              .chat-message-attachments-label {
                @extend %heading-2;
                color: $neutral;
              }

              .chat-message-attachments-preview-wrapper {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 110px;
                gap: 15px;
                padding: 10px 1px 5px 0;
                overflow-y: auto;

                .chat-message-attachment-wrapper {
                  position: relative;
                  width: 120px;
                  height: 80px;
                  border-radius: 6px;
                  box-shadow: 0 0 1px 1px rgba(143, 39, 199, 0.1);

                  .chat-message-attachment {
                    width: 120px;
                    height: 80px;
                    object-fit: cover;
                    border-radius: 6px;
                  }

                  .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    background: linear-gradient(0deg, rgba(127, 141, 160, 0.5), rgba(127, 141, 160, 0.5));
                    opacity: 1;
                    transition: opacity 0.3s ease;

                    .overlay-top-half {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 50%;
                      width: 100%;
                      padding: 5px;
                      border-radius: 6px;
                      background-color: $neutral;
                      opacity: 0.8;

                      .chat-message-attachment-name {
                        @extend %annotation;
                        color: $white;
                      }
                    }

                    .overlay-bottom-half {
                      display: flex;
                      flex-direction: row-reverse;
                      height: 50%;
                      width: 100%;
                      padding: 5px;
                      gap: 10px;

                      .overlay-icon-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $white;
                        padding: 5px;
                        border-radius: 6px;
                        box-sizing: border-box;

                        svg {
                          margin: 0;
                        }
                      }

                      .overlay-icon-wrapper:hover {
                        background: $active-default;

                        svg path:nth-of-type(2) {
                          fill: $white;
                        }

                        svg path:nth-of-type(1) {
                          stroke: $white;
                        }
                      }
                    }
                  }
                }

                .chat-message-attachment-wrapper:hover .overlay {
                  opacity: 1;
                }
              }
            }
          }
        }

        .chat-form.has-scroll {
          padding-right: 0;
        }

        .chat-form {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 64px;
          padding-right: 0;

          .chat-form-text-editor-wrapper {
            position: relative;

            .RichTextEditor {
              background: $background-color !important;
              border: 1px solid $bg-light !important;
              border-radius: 6px !important;
              font-family: $font-family !important;
              font-size: 14px !important;
            }

            .chat-form-text-editor-label {
              position: absolute;
              top: -10px;
              left: 8px;
              padding: 0 8px;
              border-radius: 6px;
              background: $background-color;
              line-height: 12px;
              @extend %caption;
              color: $dark;
            }
          }

          .chat-form-attachments {
            margin-top: 40px;

            .input {
              padding: 15px;

              .files-list-wrapper {
                width: 100%;
              }
            }
          }

          .chat-form-button {
            margin-top: 40px;
          }
        }

        .new-message-form {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 15px;

          .new-message-form-subject-input {
            height: fit-content;
            margin-bottom: 46px;
            word-break: break-word;
            @extend %body;
            color: $header;
          }

          .new-message-form-message-input {
            height: fit-content;

            textarea {
              padding: 16px;
              height: fit-content;
              resize: none;
              @extend %body;
              color: $header;
            }
          }

          .new-message-form-attachments {
            margin-top: 40px;

            .input {
              padding: 15px;

              .files-list-wrapper {
                width: 100%;
              }
            }
          }

          .new-message-form-button {
            margin-top: 40px;
          }
        }
      }
    }

    .actions-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      .edit-message-button {
        width: 100%;
        max-width: 140px;
      }

      .confirm-button {
        width: 100%;
      }
    }
  }
}

.back-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background: $av-8;
    border-radius: 8px;
  }

  .back-button:hover {
    background: $av-7;
  }

  .back-button:active {
    background: $white;
  }

  .back-button-text {
    @extend %caption;
    color: $dark;
  }
}

.RichEditor-root {
  padding: 15px;
  background: $white;
  border: 1px solid #ddd;
  border-radius: 6px !important;
  font-family: $font-family;
  font-size: 14px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  font-family: $font-family;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  font-family: $font-family;
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  font-family: $font-family;
  min-height: 140px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: $font-family;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: $font-family;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: $font-family;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.RichTextEditor {
  background: $white !important;
  border: 1px solid $bg-light !important;
  border-radius: 6px !important;
  font-family: $font-family !important;
  font-size: 14px !important;

  > div:first-child {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.RichTextEditor.disabled{
  background: $light !important;
  border: none !important;

  > div:first-child {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.RichTextEditor,
.public-DraftEditor-content {
  font-family: $font-family;
  min-height: 140px;
}

.DraftEditor-root {
  [class*='RichTextEditor__paragraph'] {
    margin: 0 !important;
  }
}

