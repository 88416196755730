@import '../../styles';

.representatives-wrapper {
  background: #FFFFFF;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
  border-radius: 6px;
  padding: 30px;
  width: 92%;

  &.list {
    padding: 0;
    box-shadow: none;
    background: transparent;
    width: 92%;
  }

  .representatives-table {
    margin-bottom: 80px;
  }

  .representatives-head {
    @extend %header-list;

    .head-Column {
      color: $neutral;

      &:nth-child(n) {
        text-align: center;
      }
      &:nth-child(4) { text-align: left }
      &:nth-child(1) {
        text-align: left;
        min-width: 190px;
      }
      &:nth-child(5) {
        text-align: left;
        min-width: 163px;
      }
      &:nth-child(2), &:nth-child(3) {
        max-width: 145px;
      }
    }
  }

  .representatives-list-item {
    @extend %list-item;

    .representatives-email {
      min-width: 190px;
      word-break: break-word;
    }

    .representatives-name, .representatives-status {
      text-align: center;
      max-width: 145px;
    }

    .representatives-action-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px
    }
    .representative-action-button {
      border: none;
      background-color: transparent;
      white-space: nowrap;
      text-align: left;
      padding: 0;
    }
  }

  .representatives-header {
    @extend %heading-1;
    color: $header;
    margin-bottom: 20px;
  }

  .representatives-description {
    width: 380px;
    @extend %body;
    color: $dark-neutral;
    margin-bottom: 36px;
  }

  .representatives-add-button {
    width: 194px;
    @extend %caption;
    border-color: $light-neutral;
    color: $dark;
    background: transparent;
  }
}

.representatives-add-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 18px 10px;
  margin-bottom: 29px;
  border-bottom: 1px solid $light-neutral;
  width: 330px;
  height: 61px;
}

.scheme-representatives-wrapper {
  .scheme-success-header {
    text-align: center;
    margin-bottom: 30px;
  }

  & .next-button {
    width: 80px;
  }
}

.representatives-popUp-header {
  @extend %heading-2;
  margin-bottom: 0;
}

.representatives-icon {
  @extend %close-icon;
  color: $header;
  .background {
    color: transparent;
  }
}

%checkbox-container {
  border: 1px solid $light-neutral;
  border-radius: 6px;
}

.add-representatives {
  padding: 20px;

  .representatives-email {
    margin-bottom: 30px;
  }
}

.representatives-notVerified {
  width: 394px;
}

.permission {
  padding: 20px;
  .representatives-email {
    margin-bottom: 30px;
    color: $dark;

    &.disabled {
      color: $light-neutral;
    }
  }

  .representatives-checkbox-wrapper {
    @extend %checkbox-container;
    padding: 10px
  }

  .permission-checkboxes-wrapper {
    @extend %checkbox-container;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .representatives-checkbox {
    width: 100%;
    color: $neutral;
    &.active {
      background-color: transparent;
    }
  }

  .full-checkbox {
    @extend %checkbox-container;
    color: $dark;
    margin-bottom: 21px;
    padding: 19px 14px;
  }

  .sms-code-wrapper {
    margin-bottom: 20px;
  }
}

.delete {
  .sms-code-wrapper {
    margin-bottom: 20px;
  }
}

@include mobile {
  .representatives-wrapper.list {
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: none;
    gap: 30px;
    width: 100%;

    .representatives-table {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 0;

      .representatives-list-item >:nth-child(n) {
        min-width: 100%;
        max-width: 100%;
      }

      .representatives-email {
        text-align: right;
      }

      .representative-action-button, .representatives-permission-text {
        text-align: right;
      }
    }
  }

  .representatives-wrapper {
    .representatives-add-button {
      width: 100%;
    }
    .representatives-description {
      width: auto;
    }
  }
}
