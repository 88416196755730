@import './src/styles';

.no-outlet {
  .wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 24px;
    background-color: $white;
  }

  &__left-side {
    padding-top: 10px;
  }

  &__right-side {
    max-width: 480px;
  }

  &__label {
    margin: 26px 0 16px 0;
    @extend %display-h1;
    color: $header;
  }

  &__description {
    margin-bottom: 24px;
    @extend %body;
    color: $dark-neutral;
  }

  &__button {
    display: flex;
    align-items: center;
    height: 60px;
    min-width: 185px;
    width: fit-content;
    padding: 20px;
  }
}

@include mobile {
  .no-outlet {
    .wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
}