@import './src/styles';

.registration-form {
  @extend %auth-container;

  .registration-icon {
    margin-top: 15px;
  }

  .email-confirmation-icon {
    margin: 10px 0;
  }

  .registration-title {
    @extend %auth-title;
    margin-bottom: 20px;
    white-space: pre-line;

    &.user {
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }

  .security-wrapper {
    margin: 20px 0;
  }

  .verification-title {
    margin-bottom: 0;
  }

  .registration-radio-group {
    margin-bottom: 30px;
  }

  .form-data {
    width: 100%;
    max-width: 100%;

    >* {
      margin-bottom: 25px;
    }

    .form {
      margin-bottom: 0;

      >* {
        margin-bottom: 29px;
        &:nth-child(3), 
        &:nth-child(4) {
          margin-bottom: 5px;
        }
      }
      .user {
        margin-bottom: 15px;
      }
      .captcha-wrapper {
        margin-bottom: 15px;
      }
    }
  }

  .security-code-title {
    margin-bottom: 10px;
  }

  .registration-description {
    text-align: center;
    width: 305px;
    color: $neutral;
    font-size: $font-size-smaller;

    &.dark  {
      color: $dark;
    }

    strong {
      color: $dark;
    }
  }

  .register-name-wrapper {
    display: flex;
    gap: 20px;

    .register-names-input {
      max-width: 210px;
    }
  }

  .form-description {
    padding: 0 16px;
    color: $bg-dark;
    text-align: center;
    letter-spacing: 0;
    line-height: 17px;
  }

  .alert-message {
    margin-bottom: 25px;
    animation: 0.3s ease-in-out marginBottom25;
  }

  @keyframes marginBottom25 {
    from {   margin-bottom: 0 }
    to {   margin-bottom: 25px }
  }

  .register-createAccount-wrapper {
    text-align: center;
    margin: 15px 0 0 0;
    font-size: $font-size-small;
    line-height: 140%;
    color: $black;
    white-space: pre-line;
  }

  .button {
    margin-bottom: 16px;
  }
  .register-account-text {
    margin-bottom: 0;
    text-align: center;
    color: $black;
    font-size: 14px;
  }

  .form-data{
    .text-center {
      margin-bottom: 16px;
    }
    hr {
      margin-bottom: 10px;
    }
  }
}

@include mobile {
  .registration-form {
    .registration-title {
      line-height: 28px;
      font-size: 20px;
      color: $header;
      text-align: center;
    }

    .register-name-wrapper {
      flex-direction: column;
      gap: 28px;
      height: fit-content;
      .input-wrapper {
        width: 100%;
      }
    }

    .react-tel-input {
      .country-list {
        width: 280px;
      }
    }
  }
}