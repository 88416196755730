@import './src/styles';

.fees {
  .fees-container {
    min-height: 650px;
    padding: 100px 33px 33px 33px;
    justify-content: flex-start;
    .tabs-container {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      padding-left: 17px;
      .fees-tab {
          padding: 20px 5px;
          border-bottom: 1px solid $light-neutral;
          min-width: 210px;
          &:active, &.active {
            border-bottom: 1px solid $active-default;
          }
        }
    }

    .fees-list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .fees-head {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 15px;
      gap: 15px;
      .head-item {
        text-align: left;
        white-space: nowrap;
        color: $neutral
      }
    }
    .dropdown-container {
      display: flex;
      width: 100%;
      margin: 69px 0 30px 0;
      .fees-dropdown {
        min-width: 330px;
      }
    }
  }
}

.fees-head > :nth-child(n) {
  flex: 1;
  text-align: center;
}

@include mobile {
  .fees {
    .fees-container {
      padding: 0;
      .tabs-container {
        width: auto;
        padding-left: 0;
        .fees-tab {
            min-width: 120px;
          }
      }
      .dropdown-container {
        display: block;
        width: 100%;
        .fees-dropdown {
            min-width: 300px;
          }
      }
      .fees-list {
        align-items: center;
        gap: 20px;
      }
    }
  }
}