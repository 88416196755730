@import '../stylesheets/styles';

.pop-up {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: fixed;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 20px 0;

  .pop-up-card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 6px;
  }

}

.high-priority-index {
  z-index: 444 !important;
}

.transaction-table-wrapper + .pop-up, .accounts-list + .pop-up, .card-wrapper + .pop-up, .send-money .pop-up, .currency-exchange .pop-up,  {
  align-items: unset;
}

.pop-up.align-on-center {
  align-items: center;
}

@include mobile {
  .pop-up {
    align-items: center;
    padding: 20px;

    .pop-up-card {
      height: fit-content;
    }
  }

  .align-on-center {
    align-items: center;
  }
}