@import './src/uikit/stylesheets/styles';
.alert-message {
  display: flex;
  align-items: flex-start;
  width: 100%;

  .alert-icon {
    min-width: 21px;
    margin-right: 5px;
  }

  .alert-text {
    margin-top: 1px;
    @extend %caption;
    text-align: left;
    white-space: pre-wrap;
  }

  &.warning {
      color: $error;
  }

  &.success {
      color: $hit;
  }

  &.info {
    color: $dark;
    .alert-icon {
      color: $active-default;
    }
  }

  strong {
    color: $danger-text;
  }
}

@keyframes showError {
  from {   margin-bottom: 0px}
  to {   margin-bottom: 36px }
}
