@import '../../../styles';

.activity {
  display: flex;
  flex-direction: column;

  .activity-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 30px;

    .wallet {
      input {
        padding-left: 19.88px;
      }
    }
  }
  .activity-filters > :nth-child(n) {
    width: 235px;
  }
  .activity-table {

    .activity-head {
      @extend %header-list;
      .head-Column {
        color: $neutral;
        &:nth-child(n) {
          text-align: left;
        }
      }
    }

    .activity-list-item {
      @extend %list-item;

      .activity-lastLogin {
        color: $neutral;
      }

      .activity-text {
        word-wrap: break-word;
      }

      .status {
        color: $hit;
        &.error {
          color: $error;
        }
      }
    }
  }

  .activity-empty {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

}

@include mobile {
  .activity {
    .activity-filters {
      justify-content: center;
    }
    .activity-filters > :nth-child(n) {
      width: 100%;
    }
    .activity-table {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .activity-head {
        display: none;
      }
      .activity-text {
        text-align: right;
      }
      .item-mobile-date {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}
